import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import axios from "axios";

import { setUpNewBookingModal } from "../entrypoints/booking/new_booking";

export default class extends Controller {
  static get targets() {
    return ["artist_slug", "art_type_id", "genres", "specialities", "dates"];
  }
  header = {
    headers: {
      Authorization: localStorage.getItem("token", ""),
    },
  };

  initialize() {
    this.boundHandleChangeCurrency = this.handleChangeCurrency.bind(this);
    this.currentCurrency = localStorage.getItem('location_currency');
  }

  connect() {
    this.getToken();

    window.addEventListener("storage", this.boundHandleChangeCurrency);
  }

  disconnect() {
    window.removeEventListener("storage", this.boundHandleChangeCurrency);
  }

  handleChangeCurrency() {
    if (this.currentCurrency !== localStorage.getItem('location_currency')) {
      let that = this
      Rails.ajax({
        type: "get",
        url: window.location.pathname + ".js",
        success: function (){
          that.currentCurrency = localStorage.getItem('location_currency');
        },
      })
    }
  }

  newBooking() {
    localStorage.setItem('isMessageSelected', false)
    axios
      .post(
        `${window.location.origin}/v3/event_bookings/clear.json`,
        this.header
      )
      .then((res) => {
        let booking_id = res.data.id;
        this.cleanBooking(booking_id);
      });
  }

  newClassBooking(event) {
    let clickedButton = event.currentTarget;
    localStorage.setItem('isMessageSelected', false)
    axios.post(
        `${window.location.origin}/v3/class_bookings/clear.json`,
        this.header
      )
      .then((res) => {
        const classPackage = JSON.parse(clickedButton.dataset.classPackage); 
        let booking_id = res.data.id;
        this.cleanClassBooking(booking_id, classPackage);
      });
  }

  newMessage() {
    localStorage.setItem('isMessageSelected', true)
    const artist_slug = this.element.getAttribute("data-artist_slug");
    document.getElementById('message_btn').addEventListener('click', function(event) {
      localStorage.setItem('messenger', 'messenger');
    });
    axios
      .post(
        `${window.location.origin}/v3/event_bookings/clear.json`,
        this.header
      )
      .then((res) => {
        let booking_id = res.data.id;
        let params = {
          booking: {
            booking_art_types_attributes: this.new_art_types(),
            booking_applications_attributes: [
              {
                artist_slug: artist_slug,
              },
            ],
          },
        };
        axios
          .put(
            `${window.location.origin}/v3/event_bookings/${booking_id}.json`,
            params,
            this.header
          )
          .then((_res) => {
            localStorage.setItem("message_info");
            setUpNewBookingModal()
          })
          .catch((error) => {
            localStorage.setItem("booking_step", "message_info");
            setUpNewBookingModal()
          });
      });
  }

  getToken() {
    let token = localStorage.getItem("token", "");
    if (token === null || token === "") {
      axios
        .get(`${window.location.origin}/api_tokens/new.json`)
        .then((response) => {
          localStorage.setItem("token", response.data.jwt);
        });
    }
  }

  cleanClassBooking(booking_id, classPackage) {
    const artist_slug = this.element.getAttribute("data-artist_slug");
    let classBooking = true
    let params = {
      class_booking: {
        location: classPackage.location,
        latitude: classPackage.latitude,
        longitude: classPackage.longitude,
        pin_location: classPackage.pin_location,
        country_id: classPackage.country_id,
        address: classPackage.address,
        skill_level: classPackage.skill_level,
        age_requirement: classPackage.age_requirement,
        days: classPackage.days,
        hours: classPackage.hours,
      }
    };
    axios.put(
      `${window.location.origin}/v3/class_bookings/${booking_id}.json`,
      params,
      this.header
    ).then((_res) => {
      localStorage.setItem(
        "booking_step",
        "select_date"
      );
      setUpNewBookingModal(classBooking, classPackage.start_time, classPackage.price_cents, artist_slug)
    })
    .catch((error) => {
      console.log(error);
      localStorage.setItem("booking_step", "select_date");
      setUpNewBookingModal(classBooking, classPackage.start_time, classPackage.price_cents, artist_slug)
    });
  }

  cleanBooking(booking_id) {
    const artist_slug = this.element.getAttribute("data-artist_slug");
    let dates =
      this.datesTarget.value.length > 0
        ? this.datesTarget.value.split(", ")
        : [];
    let params = {
      booking: {
        booking_art_types_attributes: this.new_art_types(),
        booking_dates_attributes: this.new_dates(dates),
        booking_applications_attributes: [
          {
            artist_slug: artist_slug,
          },
        ],
      },
    };
    axios
      .put(
        `${window.location.origin}/v3/event_bookings/${booking_id}.json`,
        params,
        this.header
      )
      .then((_res) => {
        localStorage.setItem(
          "booking_step",
          dates.length > 0 ? "select_location" : "select_date"
        );
        // window.location.href = "/event_bookings/new";
        setUpNewBookingModal()
      })
      .catch((error) => {
        console.log(error);
        localStorage.setItem("booking_step", "select_artist_type");
        // window.location.href = "/event_bookings/new";
        setUpNewBookingModal()
      });
  }

  new_art_types() {
    const art_type_id = this.element.getAttribute("data-art_type_id");
    return [
      {
        art_type_id: art_type_id,
        genre_slugs: this.element.getAttribute("data-genres").split(","),
        speciality_slugs: this.element
          .getAttribute("data-specialities")
          .split(","),
      },
    ];
  }

  new_dates(dates) {
    return dates.map((date) => {
      return {
        date: new Date(date),
        start_time: new Date(new Date(date).setHours(7)),
        end_time: new Date(new Date(date).setHours(19)),
      };
    });
  }
}

import Vue from 'vue'

import axios from 'axios'

import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '../../stylesheets/vuetify.scss'
import '../../stylesheets/bootstrap5_grid.scss'

import NewBooking from '../../vue/booking/NewBooking.vue'
import NewClassBooking from '../../vue/booking/NewClassBooking.vue'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCircle, faCircleCheck, faCircleXmark, faMinusSquare, faPlusSquare } from "@fortawesome/free-solid-svg-icons";

Vue.use(Vuetify)
Vue.prototype.$axios = axios
library.add(faCircleCheck, faCircleXmark, faCircle, faMinusSquare, faPlusSquare)

const fontAwesomeComponent = Vue.component('font-awesome-icon', FontAwesomeIcon)

export function setUpNewBookingModal(classBooking, start_time, price, artistSlug) {
  let newBookingModalVueInstance
  const bookingModalEl = document.getElementById("instant-quote-modal")
  const bookingModalCloseBtn = document.getElementById("instant-quote-close-btn")

  bookingModalCloseBtn.addEventListener('click', () => {
    if (bookingModalEl) {
      bookingModalEl.__x.$data.isBookingDialogOpen = false
    }
    let body = document.getElementsByTagName("body")[0]
    body.style.overflow = "unset"
    newBookingModalVueInstance.$destroy()
    newBookingModalVueInstance.$el.remove()

    document.getElementById('instant-quote-modal-body').appendChild(
      Object.assign(
        document.createElement('div'),
        { id : 'vue-new-booking' }
      )
    )
  })

  let el = document.getElementById('vue-new-booking')
  if (el != null) {
    Vue.prototype.$axios.defaults.baseURL = ''

    Vue.prototype.$axios.defaults.headers.common = {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8'
    }
    let body = document.getElementsByTagName("body")[0]
    body.style.overflow = "hidden"
    if(classBooking) {
      const props = {
        start_time: start_time,
        price: price,
        artistSlug: artistSlug
      }
      newBookingModalVueInstance = new Vue({
        el: el,
        vuetify: new Vuetify({
          theme: {
            themes: {
              light: {
                primary: '#ff8141',
                secondary: '#FFF3EC'
              },
            }
          }
        }),
        render: h => h(NewClassBooking, {props}),
        components: {
          NewClassBooking,
          fontAwesomeComponent
        }
      })

    } else {
    newBookingModalVueInstance = new Vue({
      el: el,
      vuetify: new Vuetify({
        theme: {
          themes: {
            light: {
              primary: '#ff8141',
              secondary: '#FFF3EC'
            },
          }
        }
      }),
      render: h => h(NewBooking, {}),
      components: {
        NewBooking,
        fontAwesomeComponent
      }
    })
  }

    if (bookingModalEl) {
      bookingModalEl.__x.$data.isBookingDialogOpen = true
    }
  }
}
